<template>
  <div>
    <div
      class="cardPedido"
      :class="{
        bwarning:
          pedido.pagamento || pedido.pagamento == '' || pedido.status == 2,
        binfo: pedido.status == 3,
        bsuccess: pedido.status == 4,
        bCancelado: pedido.excluido === 1,
        bdanger:
          pedido.status == 0 ||
          pedido.status == 1 ||
          pedido.pedido_erro == 1 ||
          pedido.excluido === 1,
      }"
      @click="abrirPedido([pedido])"
    >
      <div class="senha">
        <div>
          {{ pedido.senha }}
          <img
            src="@/assets/img/ifoodLogo.png"
            style="width: 40px"
            v-if="pedido.sistema == 'IFOOD'"
          />
        </div>

        <small># {{ pedido.cod_pedido }}</small>
        <small class="font-weight-bold">
          <i class="fa fa-laptop" /> {{ pedido.caixa }}

          <span v-if="pedido.id_sistema && pedido.id_sistema != ''">
            - {{ pedido.id_sistema }} {{ pedido.sistema }}
          </span></small
        >
      </div>
      <div class="tipo_entrega">
        {{ pedido.tipo_entrega }}
        <small>
          <small
            :class="{
              'text-warning':
                !pedido.pagamento ||
                pedido.pagamento == '' ||
                pedido.status == 2,
              'text-info': pedido.status == 3,
              'text-success': pedido.status == 4,
              'text-danger':
                pedido.status == 0 ||
                pedido.status == 1 ||
                pedido.pedido_erro == 1 ||
                pedido.excluido === 1,
            }"
          >
            <div v-if="pedido.excluido === 1">
              <b-icon-trash2 /> PEDIDO CANCELADO
              <small>
                {{ pedido.usuario_excluiu }}
                {{ pedido.excluido_data | moment("DD/MM/YYYY - HH:mm") }}
              </small>
            </div>
            <div v-else>
              <i
                class="fa fa-dot-circle"
                :class="{
                  'fa-exclamation-triangle': pedido.pedido_erro == 1,
                }"
              />
              {{ " " }}
              <span v-if="pedido.status == 0 || pedido.status == 1"
                >Á Confirmar</span
              >
              <span v-else-if="pedido.pedido_erro == 1"
                >PROBLEMA NO PEDIDO</span
              >
              <span
                v-else-if="
                  !pedido.pagamento ||
                  pedido.pagamento == '' ||
                  pedido.status == 2
                "
              >
                {{
                  pedido.sistema != "INTERNO-WEB"
                    ? "Pedido Confirmado"
                    : "Pendente - Pagamento"
                }}
              </span>
              <span
                v-else-if="
                  pedido.status == 3 && pedido.tipo_entrega == 'ENTREGA'
                "
                >Rota entrega</span
              >
              <span
                v-else-if="
                  pedido.status == 3 && pedido.tipo_entrega == 'BUSCAR'
                "
                >Pronto para Retirar</span
              >
              <span v-else-if="pedido.status == 4"> Concluído - Entregue </span>
            </div>
          </small>
        </small>
        <small
          style="text-left"
          v-if="pedido.tempo_entrega && pedido.tempo_entrega != ''"
          ><i class="fa fa-clock" /> {{ pedido.tempo_entrega }}</small
        >
        <small>Realizado ás {{ pedido.data | moment("HH:mm") }}</small>
        <small v-if="pedido.moto">
          <i class="fa fa-motorcycle" /> {{ pedido.moto }}
        </small>
        <small v-if="pedido.usuario_cadastro">
          <i class="fa fa-headset" /> {{ pedido.usuario_cadastro }}
        </small>
        <div
          v-if="pedido.agendamento"
          style="font-size: 10px"
          class="bg-info text-white p-1"
        >
          <b-icon-calendar-check /> Agendado em
          <span>{{ pedido.data | moment("DD/MM HH:mm") }}</span>
          para ás <br />
          {{ pedido.agendamento | moment("DD/MM/YYYY - HH:mm") }}<br />
          <small>
            <small>{{ pedido.agendamento_obs }}</small>
          </small>
        </div>
      </div>
      <div class="info">
        <div
          v-if="pedido && pedido.sistema == 'ONLINE'"
          class="font-weight-bold"
        >
          <b-icon-hdd-network></b-icon-hdd-network>
          APP {{ pedido.id_sistema }}
        </div>
        <i class="fa fa-user" />
        {{
          pedido && pedido.cliente && pedido.cliente.Nome
            ? pedido.cliente.Nome
            : ""
        }}
        <small class="">
          <span
            v-if="
              pedido &&
              pedido.cliente &&
              pedido.cliente.Bairro &&
              pedido.cliente.Bairro != ''
            "
            ><i class="fa fa-map-marked" /> {{ pedido.cliente.Bairro }}</span
          >
          <div v-if="pedido && pedido.cliente && pedido.cliente.Telefone">
            <i class="fa fa-phone" /> {{ pedido.cliente.Telefone }}
          </div>
        </small>
      </div>
      <div class="valor">
        <small
          >{{ pedido.pagamento }}

          <div
            v-if="
              pedido.pagamento == 'Cartão' &&
              pedido.integration_data &&
              pedido.integration_data.payments &&
              pedido.integration_data.payments.methods &&
              pedido.integration_data.payments.methods.length > 0 &&
              pedido.integration_data.payments.methods[0].card &&
              pedido.integration_data.payments.methods[0].card.brand
            "
          >
            <b
              >Bandeira:
              {{ pedido.integration_data.payments.methods[0].card.brand }}</b
            >
          </div>
        </small>
        R$ {{ pedido.vl_total | currency }}
        <small>
          <div
            v-if="
              pedido.pagamento == 'Dinheiro' &&
              pedido.integration_data &&
              pedido.integration_data.payments &&
              pedido.integration_data.payments.methods &&
              pedido.integration_data.payments.methods.length > 0 &&
              pedido.integration_data.payments.methods[0].cash &&
              pedido.integration_data.payments.methods[0].cash.changeFor
            "
          >
            <b
              >Troco para:
              {{
                pedido.integration_data.payments.methods[0].cash.changeFor
                  | currency
              }}</b
            >
          </div>
        </small>
        <small v-if="pedido.usuario_recebeu"
          ><small
            >{{ pedido.usuario_recebeu ? pedido.usuario_recebeu.nome : "" }}
            <small> Caixa {{ pedido.cod_caixa }} </small>
          </small></small
        >
        <div v-if="!pedido.pagamento || pedido.pagamento == ''">
          <b-button
            variant="success"
            @click="receber(pedido)"
          >
            <i class="fa fa-money-bill-alt" /> Receber
          </b-button>
        </div>
        <div
          v-if="
            (pedido.sistema == 'ONPEDIDO' || pedido.sistema == 'IFOOD') &&
            pedido.status == 0
          "
        >
          <b-button
            variant="info"
            @click="confirmarPedido(pedido)"
            ><i class="fa fa-check"></i> CONFIRMAR PEDIDO</b-button
          >
        </div>
        <div
          v-if="
            pedido.sistema == 'IFOOD' &&
            pedido.tipo_entrega == 'BUSCAR' &&
            pedido.status == 2
          "
        >
          <b-button
            variant="success"
            @click.stop="confirmarPronto(pedido)"
            ><i class="fa fa-check"></i> MARCAR COMO PRONTO</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PedidoLib from "../../libs/Pedido";
export default {
  components: {},
  props: {
    pedido: Object,
    confirmarPedido: Function,
    receber: Function,
    abrirPedido: Function,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    async confirmarPronto(pedido) {
      let res = await this.$swal({
        title: "ATENÇÃO",
        text: "Você vai trocar o status do pedido para PRONTO. Será enviado a notificação ao cliente. Deseja mesmo continuar?",
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((resp) => {
        return resp.isConfirmed;
      });
      // console.log("AAAAAAAA", pedido, res);
      if (res) {
        let result = await PedidoLib.marcarComoPronto(pedido);
        console.log("result", result);
        this.$emit("atualizarPedidos");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/pedidos.scss"
</style>
